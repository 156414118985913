import { Context, createContext } from "react";
import { InfoProps } from "../helpers/types/getTypes";

const defaultValue: InfoProps = {
  information: {
    attributes: {
      footer_header_title: "",
      footer_header_description: "",
      footer_description: "",
      createdAt: "",
      updatedAt: "",
      icon: {
        data: null,
      },
      logo: {
        data: null,
      },
      logo_light: {
        data: null,
      },
    },
  },
  footer: [],
};

const GeneralContext: Context<InfoProps> = createContext(defaultValue);

export default GeneralContext;
