import "../styles/globals.css";
import "../styles/ck-editor.css";
import type { AppContext, AppProps } from "next/app";
import "animate.css/animate.min.css";
import { useEffect, useState } from "react";
import App from "next/app";
import { client } from "../app/utils/axiosStrapi";
import {
  FooterGroupProps,
  GeneralInformationProps,
  InfoProps,
} from "../app/helpers/types/getTypes";
import { DateTime } from "luxon";
import GeneralContext from "../app/context/GeneralContext";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";

const _ = require("lodash");

type SualProps = AppProps & {
  general: {
    information: GeneralInformationProps;
    footer: FooterGroupProps[];
  };
};

function MyApp({ Component, pageProps, general }: SualProps) {
  const router = useRouter();
  const [internalGeneral, setInternalGeneral] = useState<InfoProps | undefined>(
    undefined
  );

  useEffect(() => {
    const date = DateTime.now();
    const localDate = localStorage.getItem("general-time") || "";
    if (localDate) {
      const setDate = DateTime.fromISO(localDate);
      if (date.toString() > setDate.toString()) {
        localStorage.removeItem("general-information");
        localStorage.removeItem("footer-group");
      }
    }

    const cek = localStorage.getItem("general-information") || "";
    const cek2 = localStorage.getItem("footer-group") || "";
    if (!cek && !cek2) {
      if (_.isEmpty(general.information) || _.isEmpty(general.footer)) {
        deleteCookie("_basic_load");
        router.reload();
      } else {
        const newdate = date.plus({ minutes: 30 });
        localStorage.setItem("general-time", newdate.toISO());
        localStorage.setItem(
          "general-information",
          JSON.stringify(general.information)
        );
        localStorage.setItem("footer-group", JSON.stringify(general.footer));

        setInternalGeneral(general);
      }
    } else {
      const information = JSON.parse(cek);
      const footer = JSON.parse(cek2);

      if (_.isEmpty(information) || _.isEmpty(footer)) {
        deleteCookie("_basic_load");
        localStorage.removeItem("general-information");
        localStorage.removeItem("footer-group");
        router.reload();
      } else {
        setInternalGeneral({
          information,
          footer,
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general]);

  return (
    <>
      {internalGeneral && (
        <GeneralContext.Provider value={internalGeneral}>
          <Component {...pageProps} />
        </GeneralContext.Provider>
      )}
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  await App.getInitialProps(appContext);

  let information = {};
  let footer = {};

  if (
    !getCookie("_basic_load", {
      req: appContext.ctx.req,
      res: appContext.ctx.res,
    })
  ) {
    const {
      data: { data },
    } = await client.get("/information", {
      params: {
        populate: "*",
      },
    });

    const {
      data: { data: foot },
    } = await client.get("/footer-link-groups", {
      params: {
        populate: "*",
        sort: "queue:asc",
      },
    });

    information = data;
    footer = foot;

    setCookie("_basic_load", true, {
      req: appContext.ctx.req,
      res: appContext.ctx.res,
      expires: DateTime.now().plus({ minutes: 30 }).toJSDate(),
    });
  }

  return {
    general: {
      information: information,
      footer: footer,
    },
  };
};

export default MyApp;
